import { getDefaultAnalyticsData } from "@bees-grow-shared/services";

import analytics from "@/lib/typewriter";
import { SCREEN_NAME } from "@/utils/constants";

interface TrackButtonClickLastContactsProps {
  typification: string;
  direction: string;
}

export default function trackTableSortButtonClick({
  typification,
  direction,
}: TrackButtonClickLastContactsProps) {
  const defaultAnalyticsData = getDefaultAnalyticsData();

  if (direction === "ASC") {
    analytics.buttonClicked({
      ...defaultAnalyticsData,
      screen_name: SCREEN_NAME,
      button_label: typification,
      button_name: "last_orders_sort_by_date_asc",
      url: null,
      calling_method: "CLICK",
      component_name: "last_orders_preview",
    });

    return;
  }

  analytics.buttonClicked({
    ...defaultAnalyticsData,
    screen_name: SCREEN_NAME,
    button_label: typification,
    button_name: "last_orders_sort_by_date_desc",
    url: null,
    calling_method: "CLICK",
    component_name: "last_orders_preview",
  });
}
